body.home {
  > main {
    > .browse-concepts {
      flex-shrink: 0;

      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;

      input.search-string,
      a.browse-concepts {
        background-color: #f7f7f7;
        padding: .5em .75em;
        border-radius: .5em;
        border: 0;
        cursor: pointer;
      }

      .section-title {
        span {
          white-space: nowrap;
          margin-right: 1em;
        }
        a {
          white-space: nowrap;
          text-transform: none;
          font-size: 90%;
        }
      }

      a.browse-concepts {
        text-decoration: none;
        background: $linkColor;
        color: $linkColorInverse;

        margin-top: 1em;
        margin-bottom: 1.5em;
      }

      .search-controls {
        flex-shrink: 0;

        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-top: 1em;
        margin-bottom: 1.5em;

        input.search-string {
          margin-right: 1em;
          margin-left: -.5em;
        }

        .refine {
          display: flex;
          flex-flow: row wrap;
          align-items: center;

          > * {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
          }

          label {
            padding-left: 1em;
          }
          input[type=checkbox], label {
            cursor: pointer;
          }
        }
      }

      .search-results {
        flex: 1;
        align-self: stretch;
        overflow: auto;

        box-shadow: 0 0 20px -10px rgba(black, 0.3);

        padding-bottom: 1em;
        background: white;

        table {
          @include concept-table();
        }
      }
    }
  }

  &.browser-expandable {
    > main {
      > .browse-concepts {
        .section-title {
          display: flex;
          flex-flow: row wrap;
          align-items: center;

          &:before {
            display: none;
          }

          button.toggle {
            background: transparent;
            border: 0;
            cursor: pointer;
          }
        }
      }
    }
  }

  &.browser-expanded {
    > header {
      margin-top: -1em;
      padding-bottom: 0;

      .site-title .description {
        transition: width .5s linear;
        width: 0;
        height: 0;
        overflow: hidden;
        display: inline-block;
      }
    }
    > main {
      flex-shrink: 1;
      min-height: 300px;
      // ^^ value out of thin air approximating minimally acceptable main height,
      // otherwise on very short viewports footer overlaps main due to its flex-shrink: 1,
      // which we want to restrict main height to viewport so that search results
      // can be scrolled within their own div.
      // Another (better?) solution could be to scope flex-shrink: 1
      // to tall enough viewports with a media query.

      > section.browse-concepts {
        flex-shrink: 1;
        min-height: 256px;
        padding-bottom: 0;
      }
      > section.news {
        display: none;
      }
    }
  }
}

body.home.browser-expandable > main > section.browse-concepts {
  .section-title {
    margin-left: 15vw;

    @media screen and (min-width: $bigscreenBreakpoint) {
      margin-left: calc(#{$sideOffsetBase} - #{$logoOffset});

      button.toggle {
        width: $logoOffset;
      }
    }
  }
}

body.home > main > section.browse-concepts {
  .section-title, .search-controls {
    margin-left: $sideOffsetBase;
    margin-right: $sideOffsetBase;
  }
  > a.browse-concepts {
    margin-left: calc(#{$sideOffsetBase} - .75em);
    display: block;
  }
  .search-results.status-message {
    padding-left: $sideOffsetBase;
  }
}
