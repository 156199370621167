body.concepts {
  > main > header > h1 {
    font-weight: normal;
  }

  .all-concepts {
    table {
      @include concept-table();
    }
  }
}

body.concepts > main {
  > * {
    padding-left: $sideOffsetBase;
    padding-right: $sideOffsetBase;
  }
}
