body.concept {
  > main > header h2 {
    @extend .section-title;

    .term-name {
      @include titleFontFamily(false);
      font-size: 160%;
      display: block;
      text-transform: none;
      margin-top: .5em;

      .q-open {
        margin-left: -.4em;
      }
    }
  }

  .field {
    padding-bottom: 1em;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    .field-name {
      margin: 0 1em 0 0;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
      font-size: unset;
      flex-shrink: 0;

      @media screen and (min-width: $bigscreenBreakpoint) {
        margin: 0;
        text-align: center;
      }
    }

    .abbrev {
    }

    p.field-value {
      // Single-paragraph
      margin: 0;
    }

    div.field-value {
      // Multi-paragraph
    	> :first-child {
        margin-top: 0;
      }
      .warning {
        color: red;
        font-style: italic;
      }
    }

    ul.labels {
      list-style: none;
      margin: 0;
      padding: 0;
      font-style: italic;

      li {
        display: inline;
        margin: 0;
        padding: 0;
        color: gray;

        &:after {
          content: ", ";
        }
        &:last-child:after {
          content: "";
          margin-left: 0;
        }
      }
    }

    dl.review {
      margin: 0;
      padding: 0;
      font-style: italic;
      color: gray;
      padding-left: 20px;
      font-size: 14px;

      dt, dd {
        display: inline;
        margin: 0;
        padding: 0;
      }

      .review-info {
        /* TODO */
      }

    }


  }

  article {
    border-top: 2px solid #f0f0f0;
    padding-top: 1em;
    padding-bottom: 1em;

    @media screen and (max-width: $bigscreenBreakpoint) {
      &.field {
        display: block;
      }
    }

    h3 {
      @include titleFontFamily(true);
      font-size: 100%;
      line-height: 1.2;
    }

    &.lang-ara {
      flex-direction: row-reverse;
      .localized {
        direction: rtl;
      }
    }

    &.status-valid {
    }
    &.status-notValid {
      color: red;
      opacity: 0.5;
    }
    &.status-retired {
      color: orange;
      opacity: 0.5;
    }
    &.status-superseded {
      color: gray;
      opacity: 0.5;
    }
  }
}

body.concept > main {
  padding-top: 1em;

  > header h2 {
    margin-left: $sideOffsetBase;
    margin-right: $sideOffsetBase;
  }
  .field {
    margin: 0 15vw 0 15vw;
  }
  @media screen and (min-width: $bigscreenBreakpoint) {
    .field {
      margin-left: calc(#{$sideOffsetBase} - #{$logoOffset});
      margin-right: $sideOffsetBase;

      .field-name {
        width: $logoOffset;
      }
    }
    .field.lang-ara {
      margin-right: calc(#{$sideOffsetBase} - #{$logoOffset});
      margin-left: $sideOffsetBase;
    }
  }
}
